import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lock from './Lock';
import ConnectModal from './Modal';
import Wallet from './Wallet';
import '../components/styles.css';

function Stake() {
  const [modalShow, setModalShow] = useState(false);
  const connected = useSelector((state) => state.web3.connected);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  return (
    <div className="main-farm">
      <div className="head_1">WELCOME TO BASE REWARD TOKEN STAKING</div>
      <div className="head_2">
        Stake your BRT and mint more TOKENS. Happy farming!!!
      </div>
      <br></br>
      <div className="head_2" style={{ width: 280 + 'px', margin: 'auto' }}>
        ⚠️⚠️PLEASE COMPOUND OR HARVEST ANY PENDING REWARD FIRST BEFORE ADDING
        MORE TOKENS TO YOUR STAKED TOKENS OR YOU MAY LOSE ALL YOUR UNCLAIMED
        REWARD⚠️⚠️
      </div>
      <br />
      {connected ? (
        <Wallet />
      ) : (
        <div className="head_3 mt-5">
          <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
          <span>
            <Lock />
          </span>
        </div>
      )}
    </div>
  );
}

export default Stake;
