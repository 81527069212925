import './App.css';
import './components/styles.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Stake from './components/Stake';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Wallet from './components/Wallet';
import AirdropBounty from './components/AirdropBounty';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <div className="content">
          <Switch>
            <Route exact path="/" component={Stake} />
            <Route exact path="/ab" component={AirdropBounty} />
            <Route exact path="/" component={Wallet} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
