import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import BRT from '../web3_reducer/BRTStaking.json';
import { addresses } from './addresses';

export const initInfo = createAsyncThunk(
  'InitInfo',
  async (action, thunkAPI) => {
    try {
      const fromWei = (val) => {
        return String(web3.utils.fromWei(String(val)));
      };
      let address;
      if (action) address = action;
      else address = thunkAPI.getState().web3.address;
      const { web3, brtContract, mockContract, airdropContract } =
        thunkAPI.getState().web3;

      thunkAPI.dispatch(loadInfo(address));
      thunkAPI.dispatch(loadEvents(address));
      const brtBalance = fromWei(
        await brtContract.methods.balanceOf(address).call()
      );
      const mockBalance = fromWei(
        await mockContract.methods.balanceOf(address).call()
      );
      const halvingTimestamp = await brtContract.methods
        .halvingTimestamp()
        .call();
      const rewardPerSecond = fromWei(
        await brtContract.methods.getRewardTokensPerSec().call()
      );
      const dailyReward = Number(rewardPerSecond) * 86400;
      const claimFee = fromWei(await airdropContract.methods.claimFee().call());
      // console.log(claimFee);
      const userClaim = fromWei(
        await airdropContract.methods.airdropTokenAmount().call()
      );
      // console.log(userClaim);
      const stakeFee = fromWei(await brtContract.methods.stakeFee().call());
      const unstakeFee = fromWei(await brtContract.methods.unStakeFee().call());
      const harvestFee = fromWei(await brtContract.methods.harvestFee().call());
      const compoundFee = fromWei(
        await brtContract.methods.compoundFee().call()
      );
      return {
        brtBalance,
        mockBalance,
        halvingTimestamp,
        dailyReward,
        claimFee,
        userClaim,
        stakeFee,
        unstakeFee,
        harvestFee,
        compoundFee,
      };
    } catch (error) {
      console.log('Error in loading info:', error);
      throw error;
    }
  }
);

export const loadInfo = createAsyncThunk(
  'LoadInfo',
  async (action, thunkAPI) => {
    try {
      const fromWei = (val) => {
        return web3.utils.fromWei(String(val));
      };

      let address;
      if (action) address = action;
      else address = thunkAPI.getState().web3.address;
      const { web3, brtContract, mockContract } = thunkAPI.getState().web3;
      const { brtBalance } = thunkAPI.getState().stake;
      const earned = String(
        fromWei(
          await brtContract.methods
            .calculateReward(address, web3.utils.toWei(brtBalance))
            .call()
        )
      );
      const totalValueLocked = Number(
        fromWei(await brtContract.methods.totalValueLocked().call())
      );
      const tokensLeft = fromWei(
        await mockContract.methods.balanceOf(BRT.airdropAddress).call()
      );

      return {
        earned,
        totalValueLocked,
        tokensLeft,
      };
    } catch (error) {
      console.log('Error in loading info:', error);
      throw error;
    }
  }
);

export const loadEvents = createAsyncThunk(
  'LoadEvents',
  async (action, thunkAPI) => {
    try {
      // eslint-disable-next-line
      const fromWei = (val) => {
        return web3.utils.fromWei(String(val));
      };
      let address;
      if (action) address = action;
      else address = thunkAPI.getState().web3.address;

      const { web3, airdropContract } = thunkAPI.getState().web3;

      const allBeneficiaries = await airdropContract.methods
        .getBeneficiaries()
        .call();
      // const eligible = await airdropContract.methods.isEligible(address).call();
      let eligible = true;
      let alreadyClaimed = false;
      // for (let i = 0; i < addresses.length; ++i) {
      // if (address === web3.utils.toChecksumAddress(addresses[i])) {
      // eligible = true;
      for (let j = 0; j < allBeneficiaries.length; ++j) {
        if (address === allBeneficiaries[j]) {
          eligible = false;
          alreadyClaimed = true;
          break;
        }
      }
      // break;
      // }
      // }
      const eligibleAddresses = addresses.length - allBeneficiaries.length;

      /*
            let eligibleAddresses = 0;  
            const allAddresses = await airdropContract.methods.getBeneficiaries().call();
            const isEligible = await Promise.all(
                allAddresses.map((val, _index, _array) =>
                    airdropContract.methods.isEligible(val).call()
                )
            );
            isEligible.forEach((val, _index, _array) => {
                if (val)
                    eligibleAddresses++;
            });
            */

      // const unstakeEvents = await brtContract.getPastEvents("UnStaked", {
      //     fromBlock: 0,
      //     toBlock: 'latest'
      // });
      // const harvestEvents = await brtContract.getPastEvents("Harvested", {
      //     fromBlock: 0,
      //     toBlock: 'latest'
      // });
      // const compoundEvents = await brtContract.getPastEvents("Compounded", {
      //     fromBlock: 0,
      //     toBlock: 'latest'
      // });
      // const dropClaimedEvents = await airdropContract.getPastEvents("DropClaimed", {
      //     fromBlock: 0,
      //     toBlock: 'latest'
      // });
      // let sum = 0;
      // unstakeEvents.forEach((val) => {
      //     sum += Number(fromWei(val.returnValues[3]));
      // });
      // harvestEvents.forEach((val) => {
      //     sum += Number(fromWei(val.returnValues[3]));
      // });
      // compoundEvents.forEach((val) => {
      //     sum += Number(fromWei(val.returnValues[3]));
      // });
      // let tokensClaimed = 0;
      // dropClaimedEvents.forEach((val) => {
      //     tokensClaimed += Number(fromWei(val.returnValues[1]));
      // });

      return {
        totalTokenUnlocked: 0,
        tokensClaimed: 0,
        eligible,
        eligibleAddresses,
        alreadyClaimed,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getMockTokens = createAsyncThunk(
  'GetMockTokens',
  async (_action, thunkAPI) => {
    try {
      const { mockContract, address, web3 } = thunkAPI.getState().web3;
      await mockContract.methods
        .faucet(web3.utils.toWei('100'))
        .send({ from: address });
    } catch (error) {
      console.log('Cant Fetch Tokens: ', error);
    }
  }
);

export const performAction = createAsyncThunk(
  'PerformAction',
  async (_action, thunkAPI) => {
    try {
      const { web3, brtContract, mockContract, address } =
        thunkAPI.getState().web3;
      const {
        amount,
        stakeFee,
        unstakeFee,
        harvestFee,
        compoundFee,
        currentSelection,
      } = thunkAPI.getState().stake;

      const toWei = (val) => {
        return String(web3.utils.toWei(String(val)));
      };
      // eslint-disable-next-line
      if (amount <= 0) throw 'Cant perform action on 0 Tokens';

      if (currentSelection === 'stake') {
        if (
          (
            await mockContract.methods
              .allowance(address, brtContract._address)
              .call()
          ).toString() === '0'
        ) {
          const MAX_INT =
            '115792089237316195423570985008687907853269984665640564039457584007913129639935';
          await mockContract.methods
            .approve(brtContract._address, MAX_INT)
            .send({ from: address });
        }

        await brtContract.methods
          .stake(toWei(amount))
          .send({ from: address, value: toWei(stakeFee) });
      } else if (currentSelection === 'unstake')
        await brtContract.methods
          .unstake(toWei(amount))
          .send({ from: address, value: toWei(unstakeFee) });
      else if (currentSelection === 'harvest')
        await brtContract.methods
          .harvest(toWei(amount))
          .send({ from: address, value: toWei(harvestFee) });
      else if (currentSelection === 'compound')
        await brtContract.methods
          .compound(toWei(amount))
          .send({ from: address, value: toWei(compoundFee) });

      thunkAPI.dispatch(initInfo());
    } catch (error) {
      console.log('Error in performing Action: ', error);
      throw error;
    }
  }
);

export const claimAirdrop = createAsyncThunk(
  'ClaimAirdrop',
  async (_action, thunkAPI) => {
    try {
      const { web3, airdropContract, address } = thunkAPI.getState().web3;
      const { claimFee } = thunkAPI.getState().stake.airdrop;
      await airdropContract.methods
        .claimDrop()
        .send({ from: address, value: web3.utils.toWei(claimFee) });
      thunkAPI.dispatch(initInfo());
    } catch (error) {
      console.log('Error in claiming drop: ', error);
      throw error;
    }
  }
);

const stakeSlice = createSlice({
  name: 'StakeReducer',
  initialState: {
    earned: '0',
    stakeFee: 0,
    unstakeFee: 0,
    harvestFee: 0,
    compoundFee: 0,
    brtBalance: '0',
    mockBalance: '0',
    amount: '0',
    halvingTimestamp: 0,
    dailyReward: 0,
    totalValueLocked: 0,
    totalTokenUnlocked: 0,
    currentSelection: 'stake',
    airdrop: {
      tokensClaimed: 0,
      claimFee: 0,
      eligibleAddresses: 0,
      tokensLeft: 0,
      eligible: false,
      alreadyClaimed: false,
      userClaim: 0,
    },
    loading: false,
  },
  reducers: {
    setHarvest: (state) => {
      state.currentSelection = 'harvest';
      state.amount = '0';
    },
    setStake: (state) => {
      state.currentSelection = 'stake';
      state.amount = '0';
    },
    setUnstake: (state) => {
      state.currentSelection = 'unstake';
      state.amount = '0';
    },
    setCompound: (state) => {
      state.currentSelection = 'compound';
      state.amount = '0';
    },
    changeAmount: (state, action) => {
      state.amount = action.payload;
    },
  },
  extraReducers: {
    [initInfo.fulfilled]: (state, action) => {
      state.brtBalance = action.payload.brtBalance;
      state.mockBalance = action.payload.mockBalance;
      state.dailyReward = action.payload.dailyReward;
      state.halvingTimestamp = action.payload.halvingTimestamp;
      state.airdrop.claimFee = action.payload.claimFee;
      state.airdrop.userClaim = action.payload.userClaim;
      state.stakeFee = action.payload.stakeFee;
      state.unstakeFee = action.payload.unstakeFee;
      state.harvestFee = action.payload.harvestFee;
      state.compoundFee = action.payload.compoundFee;
    },
    [initInfo.rejected]: (state) => {
      console.log('Error initializing info');
      state.loading = false;
    },
    [loadInfo.fulfilled]: (state, action) => {
      state.earned = action.payload.earned;
      state.totalValueLocked = action.payload.totalValueLocked;
      state.airdrop.tokensLeft = action.payload.tokensLeft;
      state.loading = false;
    },
    [loadEvents.rejected]: (state) => {
      console.log('Error loading info');
      state.loading = false;
    },
    [loadEvents.fulfilled]: (state, action) => {
      state.totalTokenUnlocked = action.payload.totalTokenUnlocked;
      state.airdrop.tokensClaimed = action.payload.tokensClaimed;
      state.airdrop.eligibleAddresses = action.payload.eligibleAddresses;
      state.airdrop.eligible = action.payload.eligible;
      state.airdrop.alreadyClaimed = action.payload.alreadyClaimed;
      state.loading = false;
    },
    [loadEvents.rejected]: (state) => {
      console.log('Error loading info');
      state.loading = false;
    },
    [getMockTokens.fulfilled]: (state) => {
      state.loading = false;
    },
    [getMockTokens.pending]: (state) => {
      state.loading = true;
    },
    [getMockTokens.rejected]: (state) => {
      state.loading = false;
      console.log('Failed to fetch tokens');
    },
    [performAction.fulfilled]: (state) => {
      state.amount = '0';
      state.loading = false;
    },
    [performAction.pending]: (state) => {
      state.loading = true;
    },
    [performAction.rejected]: (state) => {
      state.loading = false;
    },
    [claimAirdrop.fulfilled]: (state) => {
      state.loading = false;
    },
    [claimAirdrop.pending]: (state) => {
      state.loading = true;
    },
    [claimAirdrop.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const stakeReducer = stakeSlice.reducer;
export const { setStake, setUnstake, setHarvest, setCompound, changeAmount } =
  stakeSlice.actions;
