import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// eslint-disable-next-line
import { useDispatch, useSelector } from 'react-redux';
import { initWeb3 } from '../store/reducer/web3_reducer';
import padLock from '../images/padlock.png';

function Lock() {
  const dispatch = useDispatch();
  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="locked-container mt-5">
            <div
              className="lock-card span-cursor"
              onClick={() => {
                dispatch(initWeb3());
              }}
            >
              <div className="lock-card-p1">
                <img src={padLock} alt="PadLock" />
                <span>UNLOCK</span>
              </div>
              <p className="lock-card-p2">
                Please connect your wallet with BINANCE Smart Chain
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Lock;
