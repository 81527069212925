import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-items">
        <a
          className="item"
          href="https://t.me/BASEREWARDOFFICIAL"
          target="_blank"
          rel="noreferrer"
        >
          TELEGRAM
        </a>
        <a
          className="item"
          href="https://www.facebook.com/basereward/"
          target="_blank"
          rel="noreferrer"
        >
          FACEBOOK
        </a>
        <a
          className="item"
          href="https://twitter.com/baserewardtoken?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          TWITTER
        </a>
        <a
          className="item"
          href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0d499b25bce7aa72bb6c50e434e2ed26fe1e785d"
          target="_blank"
          rel="noreferrer"
        >
          PANCAKE SWAP BRT-BNB
        </a>
        {/* <a className="item" href="#">
          GITHUB
        </a> */}
      </div>
    </div>
  );
}

export default Footer;
