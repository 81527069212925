import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import {
  performAction,
  // eslint-disable-next-line
  getMockTokens,
  // eslint-disable-next-line
  setHarvest,
  setStake,
  // eslint-disable-next-line
  setUnstake,
  // eslint-disable-next-line
  setCompound,
  changeAmount,
  loadInfo,
  loadEvents,
} from '../store/reducer/stake_reducer';
import brtLogo from '../images/BaseLogo.png';
import BRT from '../store/reducer/web3_reducer/BRTStaking.json';

function Wallet() {
  const dispatch = useDispatch();
  const {
    brtBalance,
    mockBalance,
    // eslint-disable-next-line
    loading,
    currentSelection,
    earned,
    stakeFee,
    unstakeFee,
    harvestFee,
    compoundFee,
    amount,
    dailyReward,
    halvingTimestamp,
    totalValueLocked,
    // eslint-disable-next-line
    totalTokenUnlocked,
  } = useSelector((state) => state.stake);
  const { url, transaction } = useSelector((state) => state.web3.bscScan);
  let currentFee, maxAmount;
  if (currentSelection === 'stake') {
    currentFee = stakeFee;
    maxAmount = mockBalance;
  } else if (currentSelection === 'unstake') {
    currentFee = unstakeFee;
    maxAmount = brtBalance;
  } else if (currentSelection === 'harvest') {
    currentFee = harvestFee;
    maxAmount = earned;
  } else if (currentSelection === 'compound') {
    // eslint-disable-next-line
    currentFee = compoundFee;
    maxAmount = earned;
  }
  let poolPerc = 0;
  if (totalValueLocked > 0) poolPerc = (brtBalance / totalValueLocked) * 100;

  const oneDay = 24 * 60 * 60 * 1000;
  let nextHalving = 0;
  if (halvingTimestamp > 0)
    nextHalving = (halvingTimestamp * 1000 - Date.now()) / oneDay;

  const fixDecimals = (val) => {
    const decimals = val.split('.')[1];
    if (decimals && decimals.length > 4) return Number(val).toFixed(4);
    return val;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadInfo());
    }, 3000);
    const interval2 = setInterval(() => {
      dispatch(loadEvents());
    }, 6000);
    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  });

  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12} className="">
          <div className="wallet-container">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <div className="wallet-header">
                  <div className="item1">
                    <span className="top-txt">
                      {fixDecimals(String(dailyReward))} BRT
                    </span>
                    <span className="bottom-txt">Daily reward</span>
                  </div>
                  <div className="item2">
                    <span className="top-txt">
                      {nextHalving.toFixed(0)} days
                    </span>
                    <span className="bottom-txt">Next halving</span>
                  </div>
                  <div className="item4OfAirBounty">
                    <span className="top-txt">
                      {fixDecimals(String(totalValueLocked))} BRT
                    </span>
                    <span className="bottom-txt">Total TOKEN in pool</span>
                  </div>
                  {/* <div className="item4">
                    <span className='top-txt'>{fixDecimals(String(totalTokenUnlocked))} BRT</span>
                    <span className='bottom-txt'>Total TOKEN minted</span>
                  </div> */}
                  <div className="item5 mt-3">
                    {/* <span className='last-txt-1'></span> */}
                    <span className="blue-txt">
                      {' '}
                      <a
                        href="https://basereward.online/#services"
                        target="_blank"
                        rel="noreferrer"
                      >
                        BASE REWARD SERVICES{' '}
                        <img src={brtLogo} alt="BRT Logo" />{' '}
                      </a>{' '}
                    </span>
                  </div>
                  <div className="item6 mt-3">
                    <span className="last-txt-2">
                      {' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          'https://bscscan.com/address/' +
                          BRT.brtAddress +
                          '#code'
                        }
                      >
                        SMART CONTRACT 📄
                      </a>{' '}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="wallet-wrapper">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <div className="wallet-table-container">
                    <div className="wallet-table">
                      <Table borderless>
                        <tbody className="table-color">
                          <tr>
                            <td className="text-left lt txt">STAKE</td>
                            <td className="text-center txt">
                              {fixDecimals(brtBalance)}
                            </td>
                            <td className="text-right txt">BRT</td>
                          </tr>
                          <tr>
                            <td className="text-left txt">REWARD</td>
                            <td className="text-center txt">
                              {fixDecimals(earned)}
                            </td>
                            <td className="text-right txt">BRT</td>
                          </tr>
                          <tr>
                            <td className="text-left txt">POOL PERCENTAGE</td>
                            <td className="text-center txt">
                              {fixDecimals(String(poolPerc))}%
                            </td>
                          </tr>
                          <tr>
                            <td className="text-left txt pt-4">
                              View on BscScan
                            </td>
                            <td colSpan="2" className="text-right txt pt-4">
                              <a
                                className="bsc-scan-txt"
                                target="_blank"
                                rel="noreferrer"
                                href={url}
                              >
                                {transaction}
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="wallet-header-border"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-md-12">
                  <div className="wallet-input-container">
                    <div className="wallet-function">
                      <div className="wallet-mode">
                        <div className="wallet-mode-btn-container">
                          <div className="wallet-mode-btn">
                            <button
                              className="non-act-btn"
                              style={{
                                color:
                                  currentSelection === 'stake' ? 'black' : null,
                              }}
                              onClick={() => {
                                dispatch(setStake());
                              }}
                            >
                              STAKE
                            </button>
                          </div>
                          {/* <div>&nbsp;|&nbsp;</div>
                          <div className="wallet-mode-btn">
                            <button
                              className="non-act-btn"
                              style={{
                                color:
                                  currentSelection === 'unstake'
                                    ? 'black'
                                    : null,
                              }}
                              onClick={() => {
                                dispatch(setUnstake());
                              }}
                            >
                              UNSTAKE
                            </button>
                          </div>
                          <div>&nbsp;|&nbsp;</div>
                          <div className="wallet-mode-btn">
                            <button
                              className="non-act-btn"
                              style={{
                                color:
                                  currentSelection === 'harvest'
                                    ? 'black'
                                    : null,
                              }}
                              onClick={() => {
                                dispatch(setHarvest());
                              }}
                            >
                              HARVEST
                            </button>
                          </div>
                          <div>&nbsp;|&nbsp;</div>
                          <div className="wallet-mode-btn">
                            <button
                              className="non-act-btn"
                              style={{
                                color:
                                  currentSelection === 'compound'
                                    ? 'black'
                                    : null,
                              }}
                              onClick={() => {
                                dispatch(setCompound());
                              }}
                            >
                              COMPOUND
                            </button>
                          </div>
                         */}
                        </div>
                      </div>
                      <div className="wallet-input-div ">
                        <div className="in-txt-btn">
                          <span>
                            <input
                              type="number"
                              value={fixDecimals(amount)}
                              placeholder="0"
                              onChange={(event) => {
                                if (
                                  Number(event.target.value) > Number(maxAmount)
                                )
                                  event.target.value = maxAmount;
                                else if (Number(event.target.value) < 0)
                                  event.target.value = '0';
                                dispatch(changeAmount(event.target.value));
                              }}
                            />
                          </span>
                          <span className="itb-span-cen">BRT</span>
                          <span>
                            <button
                              className="max-btn"
                              onClick={() => {
                                dispatch(changeAmount(maxAmount));
                              }}
                            >
                              MAX
                            </button>
                          </span>
                        </div>
                      </div>

                      <div className="text-right wallet-balance-txt">
                        <span>
                          WALLET BALANCE: {fixDecimals(mockBalance)} BRT
                        </span>
                      </div>
                    </div>
                    <div className="wallet-header-border"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="wallet-footer mb-3">
                    <div>
                      <button
                        className="approve-btn"
                        onClick={() => {
                          dispatch(performAction());
                        }}
                      >
                        {currentSelection.toUpperCase()}
                      </button>
                    </div>
                    <div>
                      <a
                        href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x0d499b25bce7aa72bb6c50e434e2ed26fe1e785d"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="approve-btn">BUY BRT</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Wallet;
