import React, { useEffect, useState } from 'react';
import Lock from './Lock';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import brtLogo from '../images/BaseLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import ConnectModal from './Modal';
import {
  claimAirdrop,
  loadEvents,
  loadInfo,
} from '../store/reducer/stake_reducer';
import BRT from '../store/reducer/web3_reducer/BRTStaking.json';

function AirdropBounty() {
  const [modalShow, setModalShow] = useState(false);
  const connected = useSelector((state) => state.web3.connected);

  return (
    <div className="main-zap">
      <div className="head_1">CLAIM YOUR BRT AIRDROP & BOUNTY</div>
      <div className="head_2">Stake your BRT and unlock more BRT Token</div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
        {' '}
        <a
          href="https://youtube.com/playlist?list=PLjXUDkGIB0wsdQp3GFvYAfejnvEn6-Bfv"
          className="how-to-claim-brt-btn"
          target="_blank"
          rel="noreferrer"
        >
          HOW TO CLAIM AIRDROP
        </a>
      </div>
      <div className="head_3 mt-5">
        {/* <Lock /> */}
        {/* <Bounty /> */}

        {connected ? (
          <Bounty />
        ) : (
          <div className="head_3 mt-5">
            <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
            <span>
              <Lock />
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function Bounty() {
  // eslint-disable-next-line
  const { tokensLeft, eligibleAddresses, userClaim, alreadyClaimed, eligible } =
    useSelector((state) => state.stake.airdrop);
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(loadInfo());
    }, 3000);
    const interval2 = setInterval(() => {
      dispatch(loadEvents());
    }, 6000);
    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  });

  return (
    <Container className="background-color">
      <Row>
        <Col>
          <div className="head_3">
            <div className="bounty-container">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <div className="wallet-header" style={{ display: 'block' }}>
                    {/* <div className="item1">
                      <span className='top-txt'>{tokensClaimed} BRT</span>
                      <span className='bottom-txt'>Tokens claimed</span>
                    </div> */}
                    <div className="item1" style={{ marginBottom: '30px' }}>
                      <span className="top-txt">{tokensLeft} BRT</span>
                      <span className="bottom-txt">Tokens left</span>
                    </div>
                    {/* <div className="item2">
                      <span className="top-txt">{eligibleAddresses}</span>
                      <span className="bottom-txt">Eligible addresses</span>
                    </div> */}
                    {/*
                    <div className="item4OfAirBounty">
                      <span className='top-txt'>{claimFee} BNB</span>
                      <span className='bottom-txt'>ClaimFee</span>
                    </div>
                      */}
                    <div className="item5">
                      {/* <span className='last-txt-1'>BASE REWARD</span> */}
                      <span className="blue-txt">
                        <a
                          href="https://basereward.online/#services"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          BASE REWARD SERVICES{' '}
                          <img src={brtLogo} alt="BRT Logo" />{' '}
                        </a>{' '}
                      </span>
                      {/* </div>
                      <div className="item6"> */}
                      <span
                        className="last-txt-2"
                        style={{ marginLeft: '20px' }}
                      >
                        <a
                          href={
                            'https://bscscan.com/address/' +
                            BRT.airdropAddress +
                            '#code'
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          SMART CONTRACT 📄
                        </a>{' '}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="wallet-wrapper">
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-md-12">
                    <div className="wallet-input-container">
                      <div className="wallet-function">
                        <div className="bounty-txt-container">
                          {eligible ? (
                            <div>
                              <div className="bounty-txt-sm">
                                You are eligible for Airdrop.
                                <br />
                                Click the button below to claim your BRT tokens
                                <br />
                                {/*  {userClaim} BRT
                                <br /> */}
                                {/* Uncomment below line if you want to show BNB fee ---- start */}
                                {/* by giving {claimFee} BNB */}
                                {/* Uncomment below line if you want to show BNB fee ---- end */}
                                <span className="bounty-txt-sm-xx">
                                  (This process is automatic and you cover Fees)
                                </span>
                              </div>
                              <div>
                                <button
                                  className="claim-brt-btn"
                                  onClick={() => {
                                    dispatch(claimAirdrop());
                                  }}
                                >
                                  CLAIM BRT
                                </button>
                              </div>
                            </div>
                          ) : alreadyClaimed ? (
                            <div className="bounty-txt-sm">
                              Sorry <br />
                              You have already claimed the <br /> Airdrop
                            </div>
                          ) : (
                            <div className="bounty-txt-sm">
                              Sorry <br />
                              You are not eligible for <br /> Airdrop
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AirdropBounty;
