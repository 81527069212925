import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { web3Reducer } from './reducer/web3_reducer';
import { stakeReducer } from './reducer/stake_reducer';

// const store = createStore(reducer, applyMiddleware(thunk))
const store = configureStore({
  reducer: {
    web3: web3Reducer,
    stake: stakeReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export default store;
