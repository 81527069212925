import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import { initInfo } from '../stake_reducer';
import BRT from './BRTStaking.json';
import airdropABI from './BRTAirdrop.json';

export const initWeb3 = createAsyncThunk(
  'InitWeb3',
  async (action, thunkAPI) => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: '27e484dcd9e3efcfd25a83a78777cdf1',
        },
      },
    };

    const web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      theme: 'dark',
    });
    try {
      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);
      thunkAPI.dispatch(initContract(web3));

      provider.on('accountsChanged', (accounts) => {
        thunkAPI.dispatch(fetchAccount());
      });

      // Subscribe to chainId change
      provider.on('chainChanged', (chainId) => {
        thunkAPI.dispatch(initContract());
      });
      return {
        web3,
      };
    } catch (error) {
      console.log('Error initializing web3', error);
      throw error;
    }
  }
);

export const fetchAccount = createAsyncThunk(
  'FetchAccount',
  async (action, thunkAPI) => {
    try {
      let web3;
      if (action) web3 = action;
      else web3 = thunkAPI.getState().web3.web3;
      const address = (await web3.eth.getAccounts())[0];
      // eslint-disable-next-line
      if (!address) throw 'Account disconnected';
      thunkAPI.dispatch(initInfo(address));
      return {
        address,
      };
    } catch (error) {
      console.log('Error fetching account address', error);
      throw error;
    }
  }
);

export const initContract = createAsyncThunk(
  'InitContract',
  async (action, thunkAPI) => {
    try {
      let web3;
      if (action) web3 = action;
      else web3 = thunkAPI.getState().web3.web3;
      const binanceChainId = BRT.network;
      const chainId = await web3.eth.getChainId();
      if (chainId !== binanceChainId)
        // eslint-disable-next-line
        throw 'Please Connect to Binance Smart Chain';
      const brtContract = new web3.eth.Contract(BRT.brtAbi, BRT.brtAddress);
      const mockContract = new web3.eth.Contract(BRT.mockAbi, BRT.mockAddress);
      const airdropContract = new web3.eth.Contract(
        airdropABI,
        BRT.airdropAddress
      );
      // console.log(airdropContract);
      thunkAPI.dispatch(fetchAccount(web3));
      return {
        brtContract,
        mockContract,
        airdropContract,
      };
    } catch (error) {
      console.log('Error initializing contract: ', error);
      throw error;
    }
  }
);

const web3Slice = createSlice({
  name: 'Web3Reducer',
  initialState: {
    web3: null,
    brtContract: null,
    mockContract: null,
    airdropContract: null,
    address: null,
    connected: false,
    bscScan: {
      url: 'https://bscscan.com',
      transaction: 'Address',
    },
  },
  reducers: {
    disconnectWallet: (state) => {
      state.connected = false;
    },
  },
  extraReducers: {
    [initWeb3.fulfilled]: (state, action) => {
      state.web3 = action.payload.web3;
    },
    [initContract.fulfilled]: (state, action) => {
      state.brtContract = action.payload.brtContract;
      state.mockContract = action.payload.mockContract;
      state.airdropContract = action.payload.airdropContract;
    },
    [fetchAccount.fulfilled]: (state, action) => {
      state.address = action.payload.address;
      state.bscScan.url =
        'https://bscscan.com/address/' + action.payload.address;
      state.bscScan.transaction =
        action.payload.address.slice(0, 6) +
        '...' +
        action.payload.address.slice(38, 42);
      state.connected = true;
    },
    [initContract.rejected]: (state) => {
      state.connected = false;
    },
    [fetchAccount.rejected]: (state) => {
      state.connected = false;
    },
  },
});

export const web3Reducer = web3Slice.reducer;
export const { disconnectWallet } = web3Slice.actions;
