import React, { useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import './styles.css';
import ConnectModal from './Modal';
import Logo from '../images/BaseLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { initWeb3 } from '../store/reducer/web3_reducer';

function Header(props) {
  const [modalShow, setModalShow] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const connected = useSelector((state) => state.web3.connected);
  const transaction = useSelector((state) => state.web3.bscScan.transaction);
  const dispatch = useDispatch();

  return (
    <Container className="header-main">
      <Row>
        <Col lg={12} md={12} xs={12} className="">
          <Navbar expanded={expanded} expand="lg" className="main">
            <Navbar.Brand>
              <Link className="icon" to="/">
                {' '}
                <img src={Logo} alt="Logo" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle
              onClick={() => setExpanded(expanded ? false : 'expanded')}
              aria-controls="responsive-navbar-nav"
              className="v"
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="link-main-container"
            >
              <Nav className="mr-auto link-main pb-2">
                <NavLink
                  onClick={() => setExpanded(false)}
                  className="_12 link-color"
                  exact
                  activeClassName="selected-link"
                  to="/"
                >
                  STAKE
                </NavLink>
                <NavLink
                  onClick={() => setExpanded(false)}
                  className="_12 link-color"
                  exact
                  activeClassName="selected-link"
                  to="/ab"
                >
                  AIRDROP & BOUNTY
                </NavLink>
                <NavLink
                  onClick={() => setExpanded(false)}
                  className="_12 link-color"
                  to="#"
                >
                  VOTE
                </NavLink>
                <a
                  onClick={() => setExpanded(false)}
                  href="http://docs.basereward.online"
                  className="_12 link-color"
                  target="_blank"
                  rel="noreferrer"
                >
                  WIKI
                </a>
              </Nav>
              <Nav className="_13">
                <div className="account-wrapper">
                  <div className="account-button no-select">
                    <span className="chain-name">BNB</span>
                    <div
                      className="bunny-button primary "
                      onClick={() => {
                        connected ? setModalShow(true) : dispatch(initWeb3());
                      }}
                    >
                      <div className="connect-wallet">
                        {connected ? transaction : 'Connect Wallet'}
                        <div className="subtext"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
      <ConnectModal show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
}

export default Header;
